<template>
	<BirdDogDialog
		v-model="computedValue"
		:title="title"
		:loading="$props.loading"
		:width="$props.width"
		:max-width="$props.maxWidth"
	>
		<div class="bird-dog-item-info">
			<p v-if="!mData">No available data</p>

			<template v-else>
				<v-row dense>
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle info-subtitle">Agency Name</h5>
						<p class="info-item">{{ computedData.AgencyName }}</p>
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle info-subtitle">Principal / Contact Info</h5>
						<p class="info-item">{{ computedData.Name }}</p>
						<p class="info-item">{{ computedData.PrincipalEmail }}</p>
						<p class="info-item">{{ computedData.PrincipalPhone }}</p>
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle info-subtitle">Target Markets</h5>
						<p v-for="item in computedData.TargetMarkets" :key="item" class="info-item">{{ item }}</p>
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12">
						<div class="info-item pl-0">
							<p><strong><span>{{ computedData.NumOfProducingAgents }}</span></strong> Producing Agents</p>
							<p>Life Sales Volume:
								<strong>
									<span v-if="typeof computedData.LifeSalesVolume === 'number'">
										<span v-if="computedData.LifeSalesVolume < 0">-</span>
										<span class="with-currency">{{ formatMoney(Math.abs(computedData.LifeSalesVolume)) }}</span>
									</span>
									<span v-else>{{ computedData.LifeSalesVolume }}</span>
								</strong>
							</p>
							<p>Annuity Sales Volume:
								<strong>
									<span v-if="typeof computedData.AnnuitySalesVolume === 'number'">
										<span v-if="computedData.AnnuitySalesVolume < 0">-</span>
										<span class="with-currency">{{ formatMoney(Math.abs(computedData.AnnuitySalesVolume)) }}</span>
									</span>
									<span v-else>{{ computedData.AnnuitySalesVolume }}</span>
								</strong>
							</p>
						</div>
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle info-subtitle">Reasons for wanting to join Quility</h5>
						<div class="info-item">
							<p v-for="row in computedData.ProspectiveAgencyIsAGoodFit" :key="row">{{ row }}</p>
						</div>
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle info-subtitle">General Notes</h5>
						<div class="info-item">
							<p v-for="row in computedData.AdditionalInfo" :key="row">{{ row }}</p>
						</div>
					</v-col>
				</v-row>
			</template>
		</div>
	</BirdDogDialog>
</template>

<script>
import BirdDogDialog from './BirdDogDialog.vue'

export default {
	components: {
		BirdDogDialog,
    },
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Bird-Dog Info' },
		data: { type: Object, default: () => ({}) },
		loading: { type: Boolean, default: false },
		maxWidth: { type: String, default: '600px' },
		width: String,
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		dataConfig () {
			return [
				{ key: 'AgencyName', formatter: this.getValueOrNA },
				{ key: 'Name', formatter: this.getNameOrNA },
				{ key: 'PrincipalEmail', formatter: this.getValueOrNA },
				{ key: 'PrincipalPhone', formatter: this.getValueOrNA },
				{ key: 'TargetMarkets', formatter: this.getArrayOrNA },
				{ key: 'NumOfProducingAgents', formatter: this.getNumberOrNA },
				{ key: 'LifeSalesVolume', formatter: this.getNumberOrNA },
				{ key: 'AnnuitySalesVolume', formatter: this.getNumberOrNA },
				{ key: 'ProspectiveAgencyIsAGoodFit', formatter: this.getNotesOrNA },
				{ key: 'AdditionalInfo', formatter: this.getNotesOrNA },
			]
		},
		mData () {
			return this.data
		},
		computedData () {
			if (!this.mData) { return {} }
			return this.dataConfig.reduce((acc, { key, formatter }) => {
				acc[key] = formatter(key)
				return acc
			}, {})
		},
	},
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(value, '(0,0[.]00)')
		},
		getNameOrNA () {
			const first = this.mData.PrincipalFirstName
			const last = this.mData.PrincipalLastName
			return [first, last].filter(Boolean).join(' ') || 'NA'
		},
		getValueOrNA (key) {
			if (this.checkNullish(this.mData[key])) { return 'NA' }
			return this.mData[key]
		},
		getNumberOrNA (key) {
			if (this.checkNullish(this.mData[key])) { return 'NA' }
			return Number(this.mData[key])
		},
		getArrayOrNA (key) {
			if (!this.mData[key]?.length) { return ['NA'] }
			return this.mData[key]
		},
		getNotesOrNA (key) {
			if (this.checkNullish(this.mData[key])) { return ['NA'] }
			return this.mData[key].split('\n')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bird-dog-item-info;

.#{$block} {
	.info-subtitle {
		font-weight: 600 !important;
		margin-bottom: 0.5rem;
	}

	.info-item {
		padding-left: 1rem;
		margin-bottom: 0.5rem;
		font-size: 1.125rem;
		line-height: 1.3;
	}
}
</style>
