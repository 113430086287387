import { render, staticRenderFns } from "./BirdDogDashboardView.vue?vue&type=template&id=465ba460&scoped=true&"
import script from "./BirdDogDashboardView.vue?vue&type=script&lang=js&"
export * from "./BirdDogDashboardView.vue?vue&type=script&lang=js&"
import style0 from "./BirdDogDashboardView.vue?vue&type=style&index=0&id=465ba460&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465ba460",
  null
  
)

export default component.exports