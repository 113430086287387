<template>
	<v-row v-if="showFilters" :justify="justify">
		<v-col cols="12" sm="6" md="4" lg="3">
			<v-select
				v-model="mValue.Status"
				:items="statusOptions"
				label="Status"
				multiple
				clearable
				hide-details
				class="mt-0"
				:menu-props="{ maxHeight: 600, contentClass: 'filter-menu-content' }"
			>
				<template #prepend-item>
					<v-list-item link @click="toggleAllStatus">
						<v-list-item-action>
							<v-simple-checkbox :value="isAllStatus" @input="toggleAllStatus" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>All</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-select>
		</v-col>

		<v-col cols="12" sm="6" md="4" lg="3">
			<v-select
				v-model="mValue.Qualified"
				:items="qualifiedOptions"
				label="Qualified"
				multiple
				clearable
				hide-details
				class="mt-0"
				:menu-props="{ maxHeight: 600, contentClass: 'filter-menu-content' }"
			>
				<template #prepend-item>
					<v-list-item link @click="toggleAllQualified">
						<v-list-item-action>
							<v-simple-checkbox :value="isAllQualified" @input="toggleAllQualified" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>All</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-select>
		</v-col>
	</v-row>
</template>

<script>
import { statusOptions, qualifiedOptions } from '../config/birdDogConfigs'

const defaultFilters = {
	Status: [],
	Qualified: [],
}

export default {
    props: {
		value: {
			type: Object,
			required: true,
		},
		defaultFilters: {
			type: Object,
			default: () => ({ ...defaultFilters }),
		},
		showFilters: {
			type: Boolean,
			default: false,
		},
		justify: {
			type: String,
			default: 'center',
		},
	},
    data () {
        return {
			statusOptions: statusOptions.map(item => item.label),
			qualifiedOptions,
			isAllStatus: false,
			isAllQualified: false,
        }
    },
	computed: {
		mValue: {
			get () { return this.value },
			set (v) { this.$emit('input', { ...v }) },
		},
	},
    watch: {
		mValue: {
			deep: true,
			handler () {
				this.processAllStatusSelector()
				this.processAllQualifiedSelector()
			},
		},
		showFilters (v) {
            if (!v) {
                this.resetFilters()
            }
        },
    },
	methods: {
		toggleAllStatus () {
			if (this.isAllStatus) {
				this.isAllStatus = false
				this.mValue.Status = []
			} else {
				this.isAllStatus = true
				this.mValue.Status = [...this.statusOptions]
			}
		},
		toggleAllQualified () {
			if (this.isAllQualified) {
				this.isAllQualified = false
				this.mValue.Qualified = []
			} else {
				this.isAllQualified = true
				this.mValue.Qualified = [...this.qualifiedOptions.map(item => item.value)]
			}
		},
		processAllStatusSelector () {
			if (this.mValue.Status?.length === this.statusOptions.length) {
				this.isAllStatus = true
			} else {
				this.isAllStatus = false
			}
		},
		processAllQualifiedSelector () {
			if (this.mValue.Qualified?.length === this.qualifiedOptions.length) {
				this.isAllQualified = true
			} else {
				this.isAllQualified = false
			}
		},
		resetFilters () {
			this.mValue = { ...this.defaultFilters }
		},
	},
}
</script>

<style lang="scss">
@import '../style/style.scss';

.filter-menu-content {
	border: $border;
	border-radius: $border-radius-small;
	box-shadow: none;
}
</style>
