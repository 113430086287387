<template>
	<v-dialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="$vuetify.breakpoint.xs"
		content-class="bird-dog-info-dialog"
	>
		<v-card class="info-card">
			<v-card-title class="d-flex flex-nowrap">
				<h3 class="info-card__title">{{ title }}</h3>
				<v-btn
					icon dark class="ml-2 align-self-start flex-shrink-0"
					@click="computedValue = false"
				>
					<v-icon>fa fa-times</v-icon>
				</v-btn>
			</v-card-title>

			<div class="linear-loader-container">
				<v-progress-linear v-if="loading" color="#1A4686" indeterminate />
			</div>

			<v-card-text>
				<slot>
					<div v-html="content" />
				</slot>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Title' },
		content: { type: String, default: 'Content' },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '800px' },
		loading: { type: Boolean, default: false },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bird-dog-info-dialog {
	.info-card {
		border-radius: $border-radius-small;

		&__title {
			flex-grow: 1;
			color: inherit;
			margin-bottom: 0;
			font-size: 1.5rem;
			font-weight: bold !important;
			letter-spacing: normal;
		}

		.v-card__title {
			padding-bottom: 1rem;
			background-color: $color-header;
			color: $color-white;
			font-weight: bold;
		}

		.v-card__text {
			padding-top: 1rem;
			padding-bottom: 1.5rem;
			color: #000000 !important;
		}
	}
}
</style>
