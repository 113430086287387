<template>
	<div class="bird-dog-card-wrapper" :class="computedClass">
		<div class="card__title">
			<slot name="title">
				{{ title }}
			</slot>
		</div>

		<div class="card__loader">
			<v-progress-linear v-if="loading" :height="3" indeterminate />
		</div>

		<div class="card__content-container">
			<div class="card__content">
				<div class="card__content-title">
					<slot name="period">All-Time</slot>
				</div>

				<v-divider color="white" />

				<div class="card__content-main">
					<slot />
				</div>
			</div>

			<slot name="footer" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Title',
		},
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'bird-dog-card-wrapper--mobile',
				this.isTabletView && 'bird-dog-card-wrapper--tablet',
				this.loading && 'bird-dog-card-wrapper--loading',
				this.error && 'bird-dog-card-wrapper--error',
			]
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bird-dog-card-wrapper {
	--height-card: 17rem;
	--font-size: 1rem;
	--padding-x: 1rem;

	height: var(--height-card);
	display: flex;
	flex-direction: column;
	font-size: var(--font-size);

	&.bird-dog-card-wrapper--loading,
	&.bird-dog-card-wrapper--error {
		.card__content-main {
			opacity: 0;
		}
	}

	.card__title {
		flex-grow: 0 0 auto;
		padding: 0.5rem var(--padding-x);
		line-height: 1.25;
		background-color: $color-header;
		color: $color-white;
		font-weight: bold;
		border-top-right-radius: $border-radius-small;
		border-top-left-radius: $border-radius-small;
	}

	.card__loader {
		height: 3px;
		border-left: $border;
		border-right: $border;
	}

	.card__content-container {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		border-bottom-right-radius: $border-radius-small;
		border-bottom-left-radius: $border-radius-small;
		border: $border;
		border-top: none;
	}

	.card__content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		padding: 0.5rem var(--padding-x) 1rem;
		background-color: $color-accent;
		color: $color-white;
	}

	.card__content-title {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		font-weight: bold;
	}

	.card__content-main {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		transition: opacity 0.2s ease-in-out;
	}
}
</style>
