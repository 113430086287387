<template>
	<div>
		<!-- <QBenefitsSubmenu /> -->

		<div
			:class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-8'"
			:style="showSubmenu !== false ? 'padding-top:100px' : 'padding-top:1rem'"
		>
			<BirdDogDashboardView />
        </div>
	</div>
</template>

<script>
// import QBenefitsSubmenu from '@/components/navigation/Submenus/QBenefitsSubmenu.vue'
import BirdDogDashboardView from './BirdDogDashboardView.vue'

export default {
    components: {
		// QBenefitsSubmenu,
		BirdDogDashboardView,
	},
	props: {
		showSubmenu: { type: Boolean, default: false },
	},
}
</script>
