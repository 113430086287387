import api from '../../../store/API/QuilityAPI'

type ErrorResponse = {
	error: Error,
	message?: string,
}

type ReferralOptions = {
  Qualified: string[],
  Status: string[],
  itemsPerPage: number,
  page: number,
  search: string,
  sortBy: string[],
  sortDesc: boolean[],
}

type ReferralsResponseItem = Record<string, never>

type ReferralsResponseMeta = {
	total: number,
}

type ReferralsResponse = {
	data: ReferralsResponseItem[],
	meta: ReferralsResponseMeta,
}

type ReferralFormResponse = {
	data: ReferralsResponseItem,
}

type TotalResponse = {
	total: number
}

type SummaryResponseItem = {
	Status: string
	count: number
}

type SummaryResponse = {
	data: SummaryResponseItem[]
}

type ReferralAuditResponse = {
	data: Record<string, never>[]
}

export const loadBirdDogReferrals = async (agentCode: string, params: ReferralOptions) => {
	return api.getRequest(`/api/bird_dog_referral/${agentCode}`, params)
	    .then((response) => {
			response.data = response.data.map((item: ReferralsResponseItem) => ({
				...item,
				TargetMarkets: item.TargetMarkets ? JSON.parse(item.TargetMarkets) : []
			}))
			return response as ReferralsResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: err, message } as ErrorResponse
	    })
}

export const submitReferralForm = async (agentCode: string, form: ReferralsResponseItem) => {
	const params = {
		...form,
		TargetMarkets: JSON.stringify(form.TargetMarkets),
	}
	return api.postRequest(`/api/bird_dog_referral/${agentCode}`, params)
	    .then((response) => {
			return response as ReferralFormResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const saveReferralForm = async (agentCode: string, form: ReferralsResponseItem) => {
	const params = {
		...form,
		TargetMarkets: JSON.stringify(form.TargetMarkets),
	}
	return api.putRequest(`/api/bird_dog_referral/${agentCode}/${form.ID}`, params)
	    .then((response) => {
			return response as ReferralFormResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const loadBirdDogBonusTotal = async (agentCode: string) => {
	return api.getRequest(`/api/bonus/BirdDog/total/${agentCode}`)
	    .then((response) => {
			return response as TotalResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: err, message } as ErrorResponse
	    })
}

export const loadBirdDogBonusSummary = async (agentCode: string) => {
	return api.getRequest(`/api/bird_dog_referral/statuscount/${agentCode}`)
	    .then((response) => {
			return response as SummaryResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: err, message } as ErrorResponse
	    })
}

export const getBirdDogReferralAudit = async (referralId: string) => {
	return api.getRequest(`/api/private/audit/birddog_referral/${referralId}`)
	    .then((response) => {
			return response as ReferralAuditResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: err, message } as ErrorResponse
	    })
}
