<template>
	<BirdDogDialog
		v-model="computedValue"
		:title="title"
		:loading="$props.loading"
		:width="$props.width"
		:max-width="$props.maxWidth"
	>
		<div class="bird-dog-item-notes">
			<p v-if="!mData">No available data</p>

			<template v-else>
				<v-row>
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle notes-subtitle">History</h5>
						<v-data-table
							:items="computedStatusProgress"
							:headers="headers"
							:loading="auditLoading || auditError"
							hide-default-header
							hide-default-footer
							disable-filtering
							disable-sort
							disable-pagination
							:mobile-breakpoint="0"
							class="notes-table"
						>
						</v-data-table>
					</v-col>
				</v-row>
		
				<v-row>
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle notes-subtitle">Notes</h5>
						<div class="notes-items">
							<p v-for="row in computedPublicNotes" :key="row">{{ row }}</p>
						</div>
					</v-col>
				</v-row>
	
				<v-row v-if="hasAdminPermissions">
					<v-col cols="12">
						<h5 class="bird-dog-tracking__subtitle notes-subtitle">Internal Notes</h5>
						<div class="notes-items">
							<p v-for="row in computedInternalNotes" :key="row">{{ row }}</p>
						</div>
					</v-col>
				</v-row>
			</template>
		</div>
	</BirdDogDialog>
</template>

<script>
import moment from 'moment'
import { groupBy } from 'lodash'

import BirdDogDialog from './BirdDogDialog.vue'
import { getBirdDogReferralAudit } from '../api/index'
import { statusOptions } from '../config/birdDogConfigs.js'

const statusOptionsLabels = statusOptions.map(item => item.label)

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT_TO = 'MM/DD/YY'

export default {
	components: {
		BirdDogDialog,
    },
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Status' },
		data: { type: Object, default: () => ({}) },
		loading: { type: Boolean, default: false },
		hasAdminPermissions: { type: Boolean, default: false },
		maxWidth: { type: String, default: '600px' },
		width: String,
	},
	data () {
		return {
			auditLoading: false,
			auditError: false,
			audit: null,
		}
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		mData () {
			return this.data
		},
		computedStatusProgress () {
			if (!this.audit?.length) { return [] }
			const normalized = this.audit.map(item => ({ ...item, Status: this.getNormalizedStatus(item.Status) }))
			const grouped = groupBy(normalized, 'Status')
			const selected = Object.values(grouped).reduce((acc, item) => {
				const sorted = [...item].sort((a, b) => moment(a.LastChangeDate, DATE_FORMAT).isAfter(b.LastChangeDate, DATE_FORMAT) ? -1 : 1)
				return [...acc, sorted[0]]
			}, [])
			return selected
				.sort((a, b) => moment(a.LastChangeDate, DATE_FORMAT).isAfter(b.LastChangeDate, DATE_FORMAT) ? 1 : -1)
				.map(item => ({ ...item, LastChangeDate: moment(item.LastChangeDate, DATE_FORMAT).format(DATE_FORMAT_TO) }))
		},
		computedPublicNotes () {
			if (!this.mData?.PublicNotes) { return ['NA'] }
			return this.mData.PublicNotes.split('\n')
		},
		computedInternalNotes () {
			if (!this.hasAdminPermissions) { return [] }
			if (!this.mData?.InternalNotes) { return ['NA'] }
			return this.mData.InternalNotes.split('\n')
		},
		headers () {
			return [
				{ text: 'Date', value: 'LastChangeDate' },
				{ text: 'Status', value: 'Status' },
			]
		}
	},
	watch: {
		'data.ID': {
			immediate: true,
			handler () {
			   this.getReferralAudit()
		   	},
		},
	},
	methods: {
		async getReferralAudit () {
			if (!this.data.ID) { return }
			this.setAuditLoadingStatus(true, false)
			const res = await getBirdDogReferralAudit(this.data.ID)
			if (res.error) {
				this.setAuditLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bird-Dog Referral audit.<br>`, res.message)
			} else {
				this.audit = [...res]
				this.setAuditLoadingStatus(false, false)
			}
		},
		setAuditLoadingStatus(loading, error) {
			this.auditLoading = loading
			this.auditError = error
		},
		getNormalizedStatus (status) {
			return statusOptionsLabels.find(item => item.toLowerCase() === status?.toLowerCase?.()) || status
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bird-dog-item-notes;

.#{$block} {
	.notes-subtitle {
		font-weight: 700 !important;
		margin-bottom: 0.25rem;
	}

	.notes-table {
		::v-deep {
			tbody tr:nth-of-type(odd) {
				background-color: $color-table-bg;
			}

			tbody td {
				font-size: 1rem;
				border-color: transparent !important;

				&:first-child {
					width: 6.5rem;
				}
			}
		}
	}

	.notes-items {
		padding: 1rem 1.25rem 0.5rem;
		min-height: 4rem;
		border-radius: $border-radius-small;
		background-color: $color-notes-bg;
		font-size: 1.125rem;
		line-height: 1.3;
	}
}
</style>
