<template>
	<BirdDogAgentCard
		:loading="loading"
		:error="error"
		title="Entered vs Activated Accounts"
		class="bird-dog-accounts"
	>
		<div class="accounts">
			<div class="accounts-item">
				<div class="accounts-item__chart">
					<div class="accounts-item__value">{{ items ? referred : 'NA' }}</div>
					<div class="accounts-item__bg" :style="referredStyle" />
				</div>
				<p class="accounts-item__title">Referred</p>
			</div>
			<div class="accounts-item">
				<div class="accounts-item__chart">
					<div class="accounts-item__value">{{ items ? activated : 'NA' }}</div>
					<div class="accounts-item__bg" :style="activatedStyle" />
				</div>
				<p class="accounts-item__title">Activated</p>
			</div>
		</div>
	</BirdDogAgentCard>
</template>

<script>
import BirdDogAgentCard from './BirdDogAgentCard.vue'

import { statusOptions } from '../config/birdDogConfigs.js'

export default {
	components: {
		BirdDogAgentCard,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		referred () {
			if (!this.items?.length) { return 0 }
			return this.items.reduce((acc, item) => {
				acc += Number(item.count) || 0
				return acc
			}, 0)
		},
		activated () {
			if (!this.items?.length) return 0
			const label = statusOptions.find(item => item.key === 'Active')?.label
			return this.items.find(({ Status }) => Status?.toLowerCase?.() === label.toLowerCase())?.count || 0
		},
		referredStyle () {
			if (!this.referred) {
				return { height: '0%' }
			}
			return { height: '100%' }
		},
		activatedStyle () {
			if (!this.activated || !this.referred) {
				return { height: '0%' }
			}
			return { height: `${Math.round(100 * this.activated / this.referred)}%` }
		},
	},
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bird-dog-accounts {
	.accounts {
		position: relative;
		flex: 1 1 auto;
		margin: 1rem 0.5rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		font-size: 3rem;
		font-weight: bold;
		line-height: 1.2;
		column-gap: 1.5rem;
	}

	.accounts-item {
		flex: 1 1 auto;
		height: 100%;
		width: 50%;
		max-width: 7rem;
		display: flex;
		flex-direction: column;
	}

	.accounts-item__title {
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-size: 1rem;
		font-weight: normal;
		text-align: center;
	}

	.accounts-item__chart {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		align-items: end;
		border-bottom: 1px solid $color-primary;
	}

	.accounts-item__value {
		text-align: center;
		z-index: 1;
	}

	.accounts-item__bg {
		position: absolute;
		background-color: $color-primary;
		bottom: 0;
		width: 100%;
	}
}
</style>
