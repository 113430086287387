<template>
	<BirdDogAgentCard
		:loading="loading"
		:error="error"
		class="bird-dog-bonus-total"
	>
		<template #title>
			<p class="mb-0 font-weight-bold">Bird-Dog Bonus <span class="font-weight-regular">(total)</span></p>
		</template>

		<div class="total-value">
			<span v-if="total < 0">-</span>
			<span class="with-currency">{{ computedTotal }}</span>
		</div>

		<template #footer>
			<div class="my-2 mx-4 d-flex">
				<router-link text :to="{ name: 'BonusReport', params: { bonusType: 'BirdDog' } }" class="total-link">
					<span class="total-link__label">See All</span>
					<v-icon right>fas fa-arrow-right</v-icon>
				</router-link>
			</div>
		</template>
	</BirdDogAgentCard>
</template>

<script>
import BirdDogAgentCard from './BirdDogAgentCard.vue'

export default {
	components: {
		BirdDogAgentCard,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		total: {
			type: [Number, String],
		},
	},
	computed: {
		computedTotal () {
			if (this.checkNullish(this.total)) { return 'NA' }
			if (Number.isNaN(Number(this.total))) { return this.total }
			return this.formatNumber(Math.abs(Number(this.total)), '(0,0)')
		},
	},
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bird-dog-bonus-total {
	.total-value {
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 3rem;
		font-weight: bold;
		line-height: 1.2;
	}

	.total-link {
		display: flex;
		align-items: center;
		color: $color-primary;
		font-size: 1.125rem;

		&:hover {
			text-decoration: none !important;
		}

		.total-link__label {
			text-decoration: underline;
		}

		.v-icon {
			color: inherit;
		}
	}
}
</style>
